@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
}

*,
a,
button {
  box-sizing: border-box;
  outline: none;
}

.padding15vw {
  padding-left: 15vw;
  padding-right: 15vw;
}

.dialogModal {
  min-width: 300px;
}

a {
  color: #000;
}

.update-notification {
  background-color: #ffffff;
  color: #222222;
  display: block;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0.8em 1.2em;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .padding15vw {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

